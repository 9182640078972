@media all and (max-width: 960px) {
    nav #mobile-menu{
        display:block;
        font-size: 2em;
        color: white;
        text-shadow: 0 0 2px black, 0 0 2px black;
    }

    .social-links {
        display: none;
    }

    .contact-links {
        display: none;
    }

    .active {
        border: none;
    }

    .scrolling .active {
        border: none;
    }

    .scrolling #mobile-menu {
    }

    nav {
        width: 100% !important;
        padding: 0 !important;
    }

    nav .nav-section {
        width: 100% !important;
        display: flex;
        justify-content: space-around;
    }

    nav .nav-bar {
        position: absolute;
        display:none;
        flex-direction: column;
        top:60px;
        right:0;
        margin:0;
        padding: 60px 0 0 0;
        background-color: white;
        align-items:center;
        height: 80vh;
        width: 100%;
        transform: translateX(500px);
        transition: 0.3s ease-in-out;
        opacity: 0;
        animation: fadein 0.3s ease-in;
    }

    .nav-bar li {
        max-width: 50%;
        padding: 15px 0 !important;
        margin-left: 0 !important;
    }

    .nav-bar li a {
        text-shadow: none !important;
        text-align: start;
        min-width: 180px;
    }

    .nav-bar li a:hover {
        border: none !important;
    }

    .nav-bar .dropdown-menu{
        visibility: visible !important;
        opacity: 1 !important;
        position: relative;
    }

    .nav-bar .dropdown-menu .menu-box {
        padding: 10px 2px !important;
        background-color: whitesmoke !important;
        box-shadow: 1px 1px 5px black;
        min-width: none !important;
        max-width: 300px;
    }

    .nav-bar .dropdown-menu::before {
        border-color: transparent whitesmoke;
    }

    .nav-bar .dropdown-menu a {
        border: none;
        border-radius: 5px;
        padding: 10px 2px !important;
    }

    .nav-bar .dropdown-menu a:hover {
        background-color: transparent !important;
        color: black !important;
        border: none !important;
    }

    nav .nav-bar .dropdown-menu::before {
        margin-left: 20%;
    }

    nav .contact-button {
        min-width: 150px;
        color: white !important;
    }

    #footer{
        padding: 10px !important;
    }

    /* Home Page */

    .hero h1 {
        padding-top: 10%;
        font-size: 3em !important;
    }

    .hero h2 {
        font-size: 1.2em !important;
    }

    .hero .button-area {
        display: flex;
        flex-direction: column;
    }

    .hero .hero-text {
        width: 90% !important;
    }

    .hero .button-area #services-button {
        margin-left: 0 !important;
        margin-top: 10px;
    }

    .hero p {
        display: none;
    }

    .hero::before {
        display: none;
    }

    .home-section .between .services-items {
        flex-direction:column-reverse;
        padding: 10px 0 !important;
    }

    .between .services-items:nth-child(2) {
        flex-direction:column;
    }

    .between .services-items .services-desc h3 {
        padding: 0 !important;
        margin: 0 !important;
        text-align: center;
    }

    .between .services-items .services-desc h3:before {
        width   : 30% !important;  
        left: 30% !important;
        bottom: -10% !important;
      }    

    .home-section .between .services-items .services-desc {
        width: 100%;
        background-color: white;
        padding: 5px 10px;
    }

    .home-section .between .services-items .services-desc h2 {
        margin-left: 0;
        text-align: center;
        font-size: 1.4em;
    }

    .home-section .between .services-items .services-desc p {
        font-size: 1.3em;
        text-align: center;
        margin-left: 5px;
    }

    .home-section .between .services-items img {
        width: 100%;
    }

    .scroll-section {
        display: none;
    }

    .video-section {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 !important;
    }

    .video-section .text {
        width: 95% !important;
    }

    .video-section h3 {
        font-size: 2em !important;
    }

    .video-section p {
        text-align: center;
        align-self: center;
        font-size: 1.2em !important;
        padding: 0 40px;
        width: 100% !important;
    }

    .video-section .video {
        width: 100% !important;
    }

    .video-section iframe {
        width: 100% !important;
        height: 300px !important;
    }

    .quote .bubble {
        width: 90% !important;
    }

    .quote .author {
        padding: 10px;
    }

    /* Project */

    #root #project {
        padding: 80px 10px 20px 10px;
        align-items: center;
    }

    #root .project-page {
        padding-top: 140px !important;
    }

    #project .row {
        flex:none !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }

    #project .button-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-top: -40px;
        padding: 0;
    }

    #project .button-area a {
        position:static !important;
    }

    #project .button-area h1 {
        font-size: 2em !important;
        top: 80% !important;
        left: 5% !important;
    }

    #project .button-area h2 {
        left: 5% !important;
    }

    #project .box {
        width: 90% !important;
    }

    #project .box img {
        height:fit-content !important;
    }

    #project .box h3 {
        visibility: visible !important;
        opacity: 1 !important;
        font-size: 1em !important;
    }

    /* Services */

    #services .services-header {
        font-size: 2em !important;
    }

    #services .services-section {
        padding: 100px 15px !important;
    }

    #services .services-section .service {
        width: 100% !important;
        margin-bottom: 10px !important;
        align-items: center;
    }

    .services-section .section {
        width: 100% !important;
    }

    .services-section .image .effect {
        display: none;
    }

    #services .services-section .right {
        flex-direction: column;
    }

    #services .services-section img {
        width: 90% !important;
        max-height: 300px !important;
    }

    #services .services-section .text {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 5px !important;
    }

    .services-section .service .text h2{
        font-size: 1.5em !important;
    }

    .services-section .service .text p{
        font-size: 1em !important;
        font-weight: 400 !important;
    }

    /* Single Project */

    #single-project {
        padding: 80px 5px 80px 5px !important;
    }

    #single-project .title {
        left: 5% !important;
        top: 10% !important;
    }

    .alice-carousel__prev-btn {
        display: none !important;
    }

    .alice-carousel__next-btn {
        display: none !important;
    }

    #single-project h1 {
        font-size: 2em !important;
    }

    #single-project h1::before {
        top: 75% !important;
    }

    #single-project a {
        position:static !important;
    }

    #single-project .carousel  {
        width: 100% !important;
    }

    #single-project .sliderimage {
        height: 300px !important;
    }

    #single-project .text {
        width: 100% !important;
    }

    #single-project .text p {
        width: 100% !important;
        min-height: auto !important;
        padding: 40px 10px !important;
    }

    #single-project .alice-carousel {
        float: none !important;
        width: 100% !important;
    }

    #single-project .sliderimage {
        width: 100% !important;
    }

    /* Contact */

    #contact .contact-form .contact-info {
        position:initial !important;
    }

    #contact .contact-form form {
        position:initial !important;
    }

    #contact .contact-form { 
        flex-direction:column-reverse;
        margin-top: -70px !important;
        padding-top: 200px;
        height: fit-content !important;
    }

    #contact .contact-form form {
        width: 100% !important;
        margin-left: 0 !important;
        padding: 10px !important;
    }

    #contact .contact-form .row-one {
        flex-direction: column;
    }

    #contact .contact-form .row-one .name {
        width: 100% !important;
    }
    
    #contact .contact-form .row-one .email {
        width: 100% !important;
    }

    #map {
        top: 5% !important;
    }

    #contact #map {
        padding-top: 20px;
        width: 100% !important;
    }

    #contact .company-contact {
        padding-bottom: 600px !important;
    }

    /* About */

    #about .about-header {
        margin-left: 2% !important;
    }

    #about .about-header .title {
        position:unset !important;
        width: 100% !important;
        font-size: 1em !important;
    }

    #about .about-header #first-title {
        margin-top: 40%;
        margin-left: 10px;
    }

    #about .about-header h1 {
        font-size: 2.3em !important;
        margin-top: 25px !important;
    }

    #about .about-header h2 {
        margin-top: -20px !important;
    }

    #about .about-header #second-title {
        margin-top: 0px !important;
        margin-left: 15px !important;
    }

    #about .main-content {
        padding: 20px 0px !important;
        margin: 0 !important;
    }

    #about .main-content .text {
        padding: 5px !important;
        font-size: 0.9em !important;
        width: 100% !important;

    }
    #about .main-content .text h2 {
        font-size: 1.4em !important;
        padding: 10px !important;
    }

    #about .adv-content {
        padding: 0 5px !important;
    }

    #about .adv-content h2 {
        font-size: 1.7em !important;
    }

    #about .adv-content .content {
        flex-direction: column;
        flex-wrap: nowrap !important;
        justify-content: center !important;
    }

    #about .main-content .text p {
        padding: 20px 0px 30px 0px !important;
        font-size: 1.2em !important;
    }

    #about .adv-content .content .box{
        padding: 10px !important;
        font-size: 0.9em !important;
        width: 100%;
    }

    #about .adv-content .content .box p {
        padding: 0 !important;
    }

    #about .adv-content .content .box h3 {
        font-size: 1.3em !important;
    }

    #about .award-section {
        padding: 0 20px 0 20px !important;
    }

    .award-section .award {
        flex-direction: column;
    }

    #about .award-section .award img{
        width: 80% !important;
    }

    #about .award-section .award .text{
        padding: 0 0 50px 0!important;
    }

    #about .affil-section {
        padding: 50px 20px 0 20px !important;
    }

    #about .affil-section .text h2 {
        font-size: 2.4em !important;
    }

    #about .affil-section .affil {
        flex-wrap:wrap;
        justify-content: center;
        margin-top: 0 !important;
        flex-direction: column;
    }

    #about .affil-section .affil a{
        margin-right: 20px !important;
    }

    #about .affil-section .affil img{
        max-width: 150px !important;
        max-height: 80px !important;
    }

    /* Individual services */

    #design {
        padding: 70px 0px !important;
    }

    #design .triangle {
        top: 55% !important;
    }

    #design .title {
        width: 100% !important;
        padding-bottom: 0 !important;
        margin-bottom: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #design h1 {
        font-size: 2.2em !important;
        text-align: center;
        margin-left: 0 !important;
        left:auto !important;
    }

    #design img {
        width: 100% !important;
        height: 45vh !important;
        border-radius: 0 !important;
    }

    #design .banner {
        padding: 2px !important;
        padding-left: 0px !important;
    }

    #design .banner h2 {
        font-size: 1.5em !important;
        text-align: center;
    }

    #design .services-section {
        flex-direction: column-reverse;
        padding: 50px 2px 0 2px !important;
    }

    #design .section-two {
        margin-top: -100px;
    }

    .banner p {
        width: 90% !important;
        font-size: 1.1em !important;
    }

    .services-section .text {
        margin-left: 0 !important;
    }

        /* Footer */


    .footer {
        flex-direction: column;
        padding: 60px 0 !important;
    }

    #footer .footer-content #first-title {
        font-size: 1.7em;
    }

    #footer .footer-content h2 {
        margin-top: -50px;
        font-size: 5em;
    }

    #footer .footer-content #second-title {
        margin-top: -70px;
        font-size: 1.2em;
    }

    #footer .footer-content {
        width: 100%;
        text-align: center;
    }
    
    #footer .footer-content .explore {
        display: flex;
        flex-direction: row;
    }

    #footer .footer-content .address {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #footer .footer-content .address i {
        display: none !important;
    }

    #footer .footer-content .explore li {
        margin-left: 10px;
    }
}