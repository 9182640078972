#project {
    padding: 120px 100px 100px 100px;
    min-height: 100vh;
}

.project-page {
    display: flex;
    flex-direction: column;
}

.project-page .button-area h2 {
    position: absolute;
    bottom: -150%;
    left: 30%;
    font-weight: 400;
}

.project-page .first-row {
    padding-top: 150px !important;
}

#project .box {
   width: 45%;
   padding: 0;
   padding-bottom: 100px;
   position: relative;
   overflow: hidden;
   text-transform: uppercase;
}

#project .box img {
    width: 100%;
    height: 300px;
    animation: fadein 0.2s;
    transition: transform .5s ease;
    filter:contrast(140%);
}

#project .row {
    flex: 9;
    padding: 0 0 100px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#project .box:hover img {
    transform:scale(1.02);
    filter:contrast(300%) saturate(20%);
}

#project .button {
    margin-top: 20px;
    font-size: 2.8em;
    background-color: transparent;
    color:#332554;
    transition: all 0.3s ease-in-out;
}

#project .button i {
    margin-right: 2px;
}

#project .button-area {
    z-index: 50;
    padding: 30px;
    display: flex;
    justify-content:space-between;
    width: 60%;
    position: relative;
}

#project .button-area h1 {
    margin-top: 2px; 
    font-size: 4em;
    font-weight: 500;
    color:rgb(17, 23, 46);
    position: absolute;
    left    : 30%;
    top  : 20%;
}

#project .button-area h1::before {
    content : "";
    position: absolute;
    left    : 1%;
    bottom  : -5%;
    height  : 10px;
    width   : 50%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

#project .button:hover {
    transform:scale(1.1);
    color: rgb(41, 13, 104);
}

#project .box h3 {
    transition: all 0.3s ease-in-out;
    position: absolute;
    left: 28%;
    bottom: 5%;
    right: 10px;
    color: rgb(53, 25, 53);
    text-align: center;
    padding: -60px;
    font-weight: 500;
    font-size: 1.8em;
    max-width: 50%;
    padding: 10px 0;
    background-color: white;
}


#project .box:hover h3 {
    visibility: visible;
    opacity: 1;
}

#project .row {
    padding-top: 50px;
}