#design {
    padding: 0px 0px;
}

#design img {
    width: 100%;
    height: 400px;
    margin-top: 40px;
    border-radius: 2px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgb(70, 70, 70);
}

#design .title {
    width: 100%;
    height: 50vh;
    background-image: url("https://res.cloudinary.com/mariah07/image/upload/v1698265322/consult_pinto/header_h7jfcb_otlxxq.jpg");
    background-size: cover;
    position: relative;
}

#design .triangle {
    position: absolute;
    top: 59%;
    left: 0;
    width: 100%;
    height: 12vh;
    background-color: rgba(242, 242, 242, 0.874);
}

#design h1 {
    z-index: 1;
    position: absolute;
    top: 47%;
    left: 15%;
    font-size: 3.5em;
    border-radius: 5px;
    font-weight: 500;
    text-transform: capitalize;
    color: black;
    padding: 15px 20px;
    margin-left: 20px;
}

#design .banner {
    padding-left: 40px;
    border-left: 2px ridge grey;
    position: relative;
    margin-top: 50px;
    border-radius: 3px;
}

#design .banner .border {
    border-left: 3px solid rgba(136, 121, 159, 0.582);
    position: absolute;
    top: 15%;
    bottom: 30%;
    left:0px;
}

.services-section {
    padding: 80px 100px 80px 100px;
    display: flex;
    justify-content: space-evenly;
}

.services-section .text {
    width: 50%;
    background-color: rgba(176,171,184,0.2);
    margin-left: 20px;
    border-radius: 5px;
}

.banner p {
    text-align: start;
    width: 95%;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 1.5em;
    word-spacing: 0.05em;
    margin-left: 30px;
    margin-top: 40px;
    padding-bottom: 20px;
    color: black;
    
}

.banner h2 {
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
    color:black;
}