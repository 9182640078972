
a {
    text-decoration: none;
  }
  
  nav {
    display:flex;
    align-items: center;
    justify-content:  center;
    background-color: white;
    padding: 2px 100px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 80;
    transition: all 0.3s ease-in-out;
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.338);
  }

  nav .nav-section {
    display:flex;
    align-items: center;
    justify-content: center;
  }

  nav a img {
    opacity: 1;
    height: 60px;
    transition: 0.3s;
  }

  nav .contact-button {
    padding: 15px 30px;
    border-radius: 25px;
    font-size: 0.95em !important;
    font-weight: 600 !important;
    background: linear-gradient(70deg, rgb(65, 16, 86), rgba(109, 67, 135, 0.623));
    color: white !important;
    border: none;
  }

  .active {
    border-bottom: 1px solid lightgray;
  }

  .scrolling .active {
    border-bottom: 1px solid lightgray;
  }

  .scrolling {
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.062);
  }
  
  .scrolling .nav-bar li {
    margin-top: 8px;
  }

  .logo, .logo-scroll {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .social-links {
    width: 21%;
    display: flex;
  }

  .social-links a {
    margin-right: 20px;
    color: black;
  }

  .contact-links {
    font-size: 0.9em;
  }

  .contact-links a {
    margin-right: 20px;
    color: black;
  }

  .logo-scroll img {
    opacity: 1;
    height: 40px;
    transition: 0.3s;
  }
  
  nav .nav-bar {
    display: flex;
    list-style: none;
    float: right;
    align-items: center;
  }
  
  nav .nav-bar li {
    padding: 0px 20px;
    margin-left: 20px;
    margin-top: 8px;
  }

  nav #mobile-menu {
    display: none;
    cursor: pointer;
  }
  
  .nav-bar li a {
    color: black;
    font-weight:400;
    font-size: 1.3em;
    letter-spacing: 0.2em;
    display: inline-block;
    width: 100%;
    letter-spacing: 0.05em;
    transition: all 0.3 ease-in-out;
  }

  .nav-bar li a i {
    margin-left: 5px;
    font-size: 0.7em;
  }

  .nav-bar li a:hover {
    border-bottom: 1px solid lightgray;
    cursor: pointer;
  }

  .scrolling .nav-bar li a:hover {
    border-bottom: 1px solid lightgray;
  }

  .logo img {
    height: 60px;
    filter: contrast(200%);
    transition: 0.3s;
  }

  .nav-bar .menu:hover + .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }

  .nav-bar .dropdown-menu:not(:hover) {
    visibility: hidden;
    opacity: 0;
  }

  .nav-bar .dropdown-menu {
    transition: 0.3s;
    position: absolute;
    padding: 0;
  }

  .nav-bar .dropdown-menu::before {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 15px 16px 0;
    border-color: transparent rgba(255, 255, 255);
    transform: rotate(90deg);
    position: absolute;
    top: -1.5%;
    margin-left: 23px;
  }

  .nav-bar .dropdown-menu .menu-space {
    padding: 10px;
  }

  .nav-bar .dropdown-menu .menu-box {
    padding: 20px;
    background-color: rgba(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 20px rgb(137, 137, 137);
  }

  .nav-bar .dropdown-menu a {
    color: rgb(77, 77, 77);
    padding: 10px 14px;
    border: none;
    margin: 0;
    font-size: 1.1em;
    letter-spacing: 0.05em !important;
    background-color:white;
    margin-top: 10px;
  }

  .nav-bar .dropdown-menu a:hover {
    border: none !important;
  }

  .nav-bar .dropdown-menu a:nth-of-type(odd) {
    background-color: rgb(252, 252, 252);
  }

  .dropdown-menu .dropdown {
    display: block;
    margin: 10px 0px;
    padding: 5px 5px;
  }