@media all and (min-width: 960px) and (max-width: 1400px) {

    /* Navbar */

    .nav {
        padding: 5px 20px !important;
    }

    .nav-bar li a i {
        margin-left: 0 !important;
    }

    nav .nav-bar li {
        margin-left: 0 !important;
    }


    /* Home Page */

    .hero .hero-text {
        width: 80% !important;
    }

    .hero h1 {
        font-size: 2.5em !important;
    }

    .hero p {
        right: 0.7% !important;
    }

    .between .services-items .services-desc h2 {
        font-size: 1.3em !important;
        margin-left: 0 !important;
    }

    .between .services-items .services-desc p {
        background-color: rgba(255, 255, 255, 0.482);
        padding: 10px;
        margin-left: 0 !important;
    }

    .video-section iframe {
        width: 90% !important;
    }

    .panel h3 {
        font-size: 1.2em !important;
    }

    .panel .text {
        font-size: 1em !important;
        width: 100% !important;
    }

    /* Project */

    #project .box {
        width: 50% !important;
        float: left;
    }

    #single-project .alice-carousel {
        width: 60% !important;
    }

    #single-project .text {
        width: 40% !important;
    }

    #single-project .carousel {
        width: 100% !important;
    }

    #single-project .sliderimage {
        width: 100% !important;
    }

    #single-project .text p {
        padding: 40px 20px !important;
    }

    /* Individual Services */

    #design {
        padding: 70px 0 !important;
    }

    #design .section{
        width: 50%;
    }

    #design img {
        height: 400px !important;
    }

    #design .triangle {
        border-top: 12px solid rgba(68, 61, 79, 0.591) !important;
        border-right: 600px solid transparent !important;
    }

    .banner p {
        width: 90% !important;
    }

    .services-section .text {
        width: 100% !important;
    }

    /* About */

    #about .about-header .title {
        font-size: 2em !important;
    }

    #about .about-header .title h2 {
        margin-top: -50px !important;
    }

    #about .about-header .title #second-title {
        margin-top: -20px !important;
        margin-left: 23px !important;
    }

    #about .main-content .text h2 {
        padding: 20px !important;
    }

    #about .adv-content .content .box p {
        padding: 0px !important;
    }

    #about .main-content .text p {
        padding: 40px 20px !important;
    }

    #about .adv-content .content .box{
        flex-basis: 48% !important;
    }

    /* Contact */

    #contact .contact-form .contact-info {
        position:initial !important;
    }

    #contact .contact-form form {
        position:initial !important;
    }

    #contact .contact-form { 
        flex-direction:column-reverse;
        margin-top: 0 !important;
        padding-top: 200px;
        height: fit-content !important;
    }

    #contact .contact-form form {
        width: 80% !important;
        margin-left: 0 !important;
    }

    #map {
        top: 5% !important;
        width: 90% !important;
    }


    /*footer */
    #footer .footer-content #second-title {
        margin-left: -10px;
        font-size: 1.3em;
        text-align: start;
    }


}