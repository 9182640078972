#footer {
    background-color: rgb(244, 244, 244);
}

.footer{
    display:flex;
    justify-content:space-evenly;
    padding: 50px 80px 80px 80px;
    background-color: white;
    color: black;
}

.footer-content {
    width: 26%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-content h4 {
    margin-top: 0;
}

.footer-content h2 {
    margin-top: -70px;
    color: rgb(90, 52, 123);
    font-size: 6em;
    text-transform: uppercase;
}

.footer-content h3 {
    color: black;
    font-size: 2em;
    text-transform: uppercase;
}

.footer-content #first-title {
    letter-spacing: 0.55em;
    font-size: 2em;
    margin-left: 20px;
}

.footer-content #second-title {
    margin-top: -100px;
    font-size: 1.4em;
    margin-left: 10px;
}

.footer-content h4 {
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.footer-content ul {
    list-style: none;
    margin:0;
    padding: 0;
}

.footer-content ul li a {
    text-decoration: none;
    color: rgb(90, 52, 123);
    transition: all 0.3 ease-in-out;
}

.footer-content ul li a:hover {
    color: black;
}

.footer-content ul {
    margin-left: 10px;
    font-size: 1.3em;
}

.footer-content .social i {
    font-size: 5em;
}

.footer-content .social {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.footer-content .explore {
    margin-top: 10px;
}

#footer .footer-content .explore li {
    margin-bottom: 5px;
    font-size: 1.1em;
    font-weight: 500;
}

#footer .footer-content .social li {
    margin-left: 10px;
}

#footer .footer-content .address li {
    display: flex;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: 1.5em;
}

#footer .footer-content .address .phone {
    margin-top: -20px;
}

#footer .footer-content .address li i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    margin-right: 20px;
    color: rgb(90, 52, 123);
}

#footer .copy-right {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: whitesmoke;
    color: black;
    font-size:0.8em;
}