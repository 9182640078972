
#about .about-header {
    padding: 150px 100px 0 0;
    margin-left: 15%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    background-size:cover;
    height: 40vh;
    position: relative;
    overflow: hidden;
}

#about .about-header h1 {
    margin-top: 0px;
    font-size: 4.8em;
    font-weight: 600;
}

#about .about-header h1::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 72%;
    height  : 10px;
    width   : 10%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

#about .about-header h2 {
    margin-top: -30px;
    letter-spacing: 0.09em;
    font-weight: 500;
}

#about .main-content {
    padding: 40px 0 0 0;
    margin: 0 200px 0 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#about .main-content .text {
    padding: 0;
    width: 100%;
    letter-spacing: 0.07em;
    word-spacing: 0.2em;
    color: rgb(43, 43, 43);
}

#about .main-content .text h2 {
    font-weight: 500;
    line-height: 2em;
    font-size: 2.2em;
    text-align: start;
    border-left: 1px dotted black;
    border-top: 1px dotted black;
    color:rgb(58, 42, 83);
    padding: 20px 200px;
    background:  linear-gradient(135deg, rgba(255,255,255,1) 60%, rgba(176,171,184,0.2) 50%);
}

#about .main-content .text p {
    padding: 70px 200px 30px 150px;
    background-color: rgba(176,171,184,0.2);
    line-height: 2em;
    font-size: 1.5em;
    margin-top: -40px;
    text-align: start;
    font-style: italic;
}

#about .adv-content {
    padding: 30px 150px 0 150px;
}

#about .adv-content h2 {
    position: relative;
    font-size: 3em;
    margin-bottom: 40px;
    margin-left: 100px;
    font-weight: 500;
    color:rgb(56, 30, 81);
}

#about .adv-content h2::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 90%;
    height  : 10px;
    width   : 10%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

#about .adv-content .content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

#about .adv-content .content .box{
    flex-basis: 45%;
    padding: 20px 10px;
    margin-bottom: 50px;
    letter-spacing: 0.05em;
    word-spacing: 0.05em;
    line-height: 1.5em;
    background-color: rgba(176,171,184,0.2);
    border-radius: 10px;
    text-align: justify;
}

#about .adv-content .content .box i {
    color: black;
    margin-left: 40px;
    font-size: 3em;
}

#about .adv-content .content .box h3 {
    color:rgb(51, 35, 14);
    text-align: start;
    margin-left: 40px;
    text-transform: capitalize;
    padding-bottom: 5px;
    font-size: 1.2em;
    font-weight: 600;
}

#about .adv-content .content .box p {
    font-size: 1.3em;
    line-height: 1.4em;
    padding: 0 40px;
}

#about .award-section {
    padding: 80px 150px 0 150px;
    
}

#about .award-section h2{
    position: relative;
    margin-top:0;
    font-size: 4em;
    color:rgb(58, 42, 83);
    font-weight: 500;
}

#about .award-section h2::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 90%;
    height  : 10px;
    width   : 5%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

#about .award-section .award{
    display: flex;
    border-bottom: 1px solid grey;
}

#about .award-section .award a {
    color:rgb(94, 23, 94);
    text-decoration: none;
}

#about .award-section .award a:hover {
    color: grey;
}

#about .award-section .award img{
    width: 50%;
    object-fit: cover;
}

#about .award-section .award .text h3 {
    text-transform:uppercase;
    font-weight: 600; 
}

#about .award-section .award .text{
    padding: 20px;
    font-size: 1.2em;
    line-height: 1.6em;
}

#about .affil-section {
    padding: 100px 80px 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#about .affil-section .text{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 4%;
    position: relative;
}

#about .affil-section .text h2 {
    font-size: 3.5em;
    font-weight: 500;
    position: relative;
}

#about .affil-section .text h2::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 90%;
    height  : 10px;
    width   : 20%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

#about .affil-section .text h3 {
    margin-top: 0;
    font-size: 1.3em;
    font-weight: 500;
    width: 70%;
}

#about .affil-section .affil { 
    display: flex;
}

#about .affil-section .affil .container { 
    width: 30%;
    margin-left: 10px;
    margin-bottom: 10px;
}

#about .affil-section .affil a{
    min-height: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px outset rgb(219, 219, 219);
}


#about .affil-section .smaller img{
    max-width: 90%;
}

#about .affil-section .larger img{
    max-width: 40%;
}