body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  font-family: "Barlow", sans-serif;

}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}