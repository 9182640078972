.home-section {
    width:100%;
    background-color: white;
  }

  .hero .temp-background {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;
  }
  
  .hero {
    background-color: white;
    background-image: url('https://res.cloudinary.com/mariah07/image/upload/v1698268457/consult_pinto/background_pinto_about.png');
    background-size: 60% 100%;
    background-repeat: no-repeat;
    background-position: 150% 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: rgb(245, 245, 245);
    padding-top: 10px;
    padding-bottom: 150px;
    max-height: 100vh;
    width:inherit;
    overflow: hidden;
  }

  .hero .button-area {
    margin-top: 70px;
  }

  .hero .button-area button:hover, a:hover{
    cursor: pointer;
  }

  .hero .button-area button, #project-button {
    padding: 15px 0;
    width: 280px;
    font-size: 1.1em;
    font-weight: 600;
  }

  .hero .button-area #project-button {
    padding: 15px 30px;
    border-radius: 25px;
    background: linear-gradient(70deg, rgb(65, 16, 86), rgba(109, 67, 135, 0.623));
    color: white;
    border: none;
    text-align: center;
  }

  .hero .button-area #services-button {
    margin-left: 20px;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.091);
    border: 1px solid purple;
    color: purple;
  }
  
  .hero .hero-text {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    padding: 200px 0 20px 0;
    margin-left: 15%;
    width: 50%;
    z-index: 1;
  }

  .hero .box-content {
    position: absolute;
    top: 30%;
    right: 13%;
    width: 30%;
    height: 50%;
    background-color: white;
  }
  
  .hero h1{
    position: relative;
    margin:0;
    font-size: 5em;
    font-weight: 500;
    color: rgb(61, 44, 72);
    line-height: 1.1em;
    z-index: 2;
  }

  .hero h1::before {
    content : "";
    position: absolute;
    left    : 0%;
    bottom  : -30%;
    height  : 10px;
    width   : 40%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

  .hero h2 {
    color: black;
    font-weight: 400;
    font-size: 2em;
    margin-top: 100px;
    width: 70%;
  }
  
  .hero span {
    position: absolute;
    right: 8.5%;
    bottom: 11%;
    font-size: 1.4em;
    color: black;
    z-index: 1;
    width: 0;
  }

  .hero span button {
    position: relative;
    background-color: transparent;
    border: none;
    color:white;
    padding-right: 90px;
    transform: rotate(90deg);
    cursor: pointer;
    font-size: 0.7em;
    font-weight: 400;
    font-style: italic;
  }

  .hero span button::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-top: 2px solid white;
    border-left: 70px solid white;
    border-right: 70px solid white;
    right: -50%;
    bottom: 45%;
    z-index: 2;
  }

  .hero .border-pulse {
    position: absolute;
    right: -37%;
    bottom: -40%;
    width: 7px;
    height: 20px;
    background: linear-gradient(
      183deg,
      rgb(41, 41, 41),
      rgba(55, 13, 51, 0.5),
      transparent 50%
    );
    animation: animateTop 2.5s linear infinite;
  }

  .between {
    position: relative;
    overflow: hidden;
  }

  .between .anchor {
    position: absolute; 
    top: 0px;
    right: 0;
  }

  .between .services-items {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 40px;
    background-color: rgb(63, 46, 62);
    overflow: visible;
  }

  .between .services-items .services-desc {
    padding-top: 20px;
    z-index: 1;
    width: 47%;
  }

  .between .services-items .services-desc:nth-child(2) {
    min-height: 450px;
  }

  .between .services-items .services-desc h2 {
    
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    line-height: 1.6em;
    letter-spacing: 0.03em;
    margin-left: 80px;
    color:rgb(63, 46, 62);
  }

  .between .services-items .services-desc h3 {
    position: relative;
    font-weight: 500;
    color:rgb(63, 46, 62);
    margin-left: 80px;
    font-size: 1.3em;
    margin-top: -10px;
    margin-bottom: 40px;
  }

  .between .services-items .services-desc h3:before {
    content : "";
    position: absolute;
    left    : 0%;
    height  : 10px;
    width   : 12%;  
    top: 100%;
    border-bottom:5px solid rgb(114, 53, 154);
  }

  .between .services-items .services-desc #title3:before {
    bottom  : -50%;
  }

  .between .services-items .services-desc p {
    text-align: justify;
    padding-top: 10px;
    font-weight: 400 !important;
    font-size: 1.35em;
    margin-left:100px;
    line-height: 1.6em;
    color:black;
  }

  .between .services-items img {
    z-index: 1;
    width: 35%;
    height: 300px;
    box-shadow: 10px 10px 0px rgb(78, 52, 84);
  }

  .between .services-items:nth-child(1) {
    padding-top: 100px;
  }

  .between .services-items:nth-child(3) {
    padding-bottom: 100px;
  }

  .between .services-items:nth-child(1)::before {
    background-color: #fff;
    content: '';
    height: 1008px;
    left: 0;
    position: absolute;
    top: 0;
    width:80%;
  }

  .between .services-items:nth-child(2)::before {
    background-color: #fff;
    content: '';
    height: 808px;
    right: 0;
    position: absolute;
    top: 35%;
    width: 57%;
  }

  .between .services-items:nth-child(3)::before {
    background-color: #fff;
    content: '';
    height: 1000px;
    left: 0;
    position: absolute;
    top: 63%;
    width: 65%;
  }
  
  .scrolltop h2 {
    font-weight: 500;
    color: rgb(63, 46, 62);
    font-size: 2.4em;
    margin-bottom: 0px;
    width: 70%;
  }
  
  .scroll-bottom {
    display: flex;
  }
  
  .scrolltop {
    padding: 20px;
    background: linear-gradient(60deg, white 66.9%, rgb(63, 46, 62) 35%);
    top: 0px;
    position:sticky;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 100;
    text-align: center;
    height: 20vh;
  }

  .scrolltop .button-area {
    width: 20%;
    display: flex;
    justify-content: space-evenly;
  }

  .scrolltop .button-area button {
    font-size: 2em;
    background-color: transparent;
    border: none;
    color: whitesmoke;
    padding: none;
    height: 40px;
    display: flex;
    align-items: center;
  }

  
  .scrolltop .button-area button:hover {
    cursor: pointer;
  }

  .animation {
    background-color: white;
    width: 50%;
  }
  
  .animation .panel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    position: sticky;
    top: calc(10vh);
  }

  .panel h3 {
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(63, 46, 62);
    font-size: 1.7em;
    line-height: 1.3em;
    padding-bottom: 20px;
    text-align: start;
    margin-left: 12%;
    position: relative;
  }

  .panel h3::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 70%;
    height  : 10px;
    width   : 25%;  
    border-bottom:5px solid rgb(114, 53, 154);
  }

  .panel a {
    text-transform: capitalize;
    color:rgba(63, 46, 62);
    padding: 10px 10px;
    border-bottom: 1px solid purple;
  }

  .panel .text {
    background-color: white;
    border-radius: 5px;
    padding: 15px 80px;
    height: fit-content;
    font-weight:400;
    width: 85%;
    z-index: 1;
    font-size: 1.2em;
    line-height: 1.9em;
    text-align: center;
    letter-spacing: 0.05em;
    color:rgb(71, 71, 71);
    border-bottom: 1px dashed rgba(63, 46, 62, 0.285);
    border-right: 1.2px dashed rgba(63, 46, 62, 0.285);
    padding-bottom: 40px;
  }

  .panel .text p {
    text-align: justify;
  }

  #panel1 {
    background-color: white;
    height: 80vh;
  }
  
  #panel2 {
    background-image: url('../img/church.jpg');
    background-repeat:no-repeat;
    background-size:contain;
    background-color: rgb(63, 46, 62);
    border: 80px solid white;
    border-top: 120px solid white;
    height: 160vh;
  }
  
  #panel3 {
    background-color:white;
    height: 80vh;
  }

  #panel5 .text {
    border: none;
    border-top: 1px dashed purple;
    border-left: 1.2px dashed purple;
    padding-top:50px;
    margin-top: -50px;
  }

  #panel4 {
    background-image: url('../img/ISL_Building_Pic.jpg');
    background-repeat:no-repeat;
    background-size:contain;
    background-color: rgb(63, 46, 62);
    border: 80px solid white;
    border-top: 120px solid white;
    height: 160vh;
  }

  #panel5 {
    background-color: white;
    height: 80vh;
  }
  
  #panel6 {
    background-image: url('../img/armourysquare-bighero_960_411_c1.jpg');
    background-repeat:no-repeat;
    background-size: contain;
    background-color: rgb(63, 46, 62);
    border: 80px solid white;
    border-top: 120px solid white;
    height: 80vh;
  }

  .panel span {
    position: absolute;
    border-radius: 100vmax;
  }

  @keyframes animateTop {
    25% {
      width: 100%;
      opacity: 1;
    }

    30%,
    100% {
      opacity: 0;
    }
  }
  
  .bottom-panel {
    height: 100px;
    z-index:1;
  }

  .video-section {
    display: flex;
    background-color: white;
    align-items: start;
    justify-content: space-evenly;
    padding: 100px 60px 100px 60px;
    width: 100%;
  }

  .video-section .text {
    width: 45%;
  }

  .video-section .text h3 {
    font-size: 3em;
    font-weight: 500;
    position: relative;
  }

  .video-section .text h3::before {
    content : "";
    position: absolute;
    left    : 0%;
    top  : 100%;
    height  : 10px;
    width   : 25%;  
    border-bottom:5px solid rgb(114, 53, 154);
  }

  .video-section p {
    padding-bottom: 40px;
    font-size: 2.3em;
    font-weight: 500;
    margin-top: 70px;
    border-bottom: 10px solid rgba(255, 255, 255, 0.274);
    border-right: 1px dashed rgba(255, 255, 255, 0.274);
    color: black;
    text-align: start;
    line-height: 1.7em;
    background:  linear-gradient(135deg, transparent 40%, rgba(176,171,184,0.2027603277639181) 40%);
  }

  .video-section .video {
    width: 45%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    box-shadow: 7px 5px 0px rgb(63, 46, 62);
  }

  .video-section iframe {
    margin-top: 20px;
    width: 100% !important;
    border: 1px solid black;
  }

  .end-page {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .end-page .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 200px;
  }

  .end-page .questions h2 {
    font-weight: 400;
    margin-top: 5px;
    color: black;
    font-size: 2em;
    letter-spacing: 0.05em;
    padding-bottom: 20px;
  }

  .end-page .questions a {
    border: 1px solid white;
    background-color: transparent;
    color: black;
    margin-top: -20px;
    text-transform: uppercase;
    padding:20px 40px;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: -50px;
    padding: 15px 30px;
    border-radius: 25px;
    background: linear-gradient(70deg, rgb(65, 16, 86), rgba(109, 67, 135, 0.623));
    color: white;
    border: none;
  }

  .end-page .questions a:hover {
    background-color: rgba(176,171,184,0.2027603277639181);
    cursor: pointer;
  }