

.contact-main {
    background: linear-gradient(140deg, rgba(255, 255, 255, 0.836) 50%, rgb(58, 47, 66) 50%);
}

#contact .contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 130vh;
    margin-top: -100px;
}

#contact .contact-form .contact-info {
    position: absolute;
    top: 26%;
    left: 10%;
    padding: 15px 50px;
    background-color: rgba(58, 47, 66, 0.9) ;
    color: white;
    z-index: 3;
}

#contact .contact-form .contact-info h4 {
    font-size: 2em;
    font-weight: 500;
}

#contact .contact-form .contact-info ul {
    list-style-type: none;
    
}

#contact .contact-form .contact-info li  {
   display: flex;
   margin-left: -40px;
   margin-bottom: 20px;
   font-size: 1.2em;
   font-weight: 400;
}

#contact .contact-form .contact-info li i {
    font-size: 2em;
    padding-right: 20px;
}

#contact .contact-form .contact-info li .phone {
    margin-left: 6px;
}

#contact .contact-form .contact-info li a {
    color: white;
    display: block;
}

#contact .contact-form .contact-info .social-media {
    padding-top: 50px;
}

#contact .contact-form .contact-info .social-media a {
    margin-right: 20px;
    color: white;
}

#contact .contact-form form {
    width: 60%;
    position: absolute;
    top: 27%;
    left: 11%;
    background-color: white;
    padding: 50px;
    padding-left: 150px;
    margin-left: 20%;
}

#contact .contact-form .form-row {
    padding-top: 15px;
    position: relative;
}

#contact .contact-form .form-row label {
    position: absolute;
    left: 10px;
    padding: 5px 0;
    top: 20px;
    pointer-events: none;
    transition: 0.3s;
}

#contact .contact-form .form-row label span {
    color: red;
    font-size: 0.7em;
}

#contact .contact-form .row-one {
    display: flex;
    justify-content: space-between;
}

#contact .contact-form .row-one .name {
    width: 47%;
}

#contact .contact-form .row-one .email {
    width: 47%;
}

#contact .contact-form .form-row .input-one {
    width: 100%;
}

#contact .contact-form .form-row .input-two {
    width: 100%;
}

#contact .contact-form .form-row input {
    width: 45%;
    background-color: white;
    border: none;
    border-bottom: 1px solid grey;
    height: 40px;
    text-size-adjust: 2em;
    padding: 10px;
    font-size: large;
}

#contact .contact-form .form-row textarea {
    width: 100%;
    height: 200px;
    border: none;
    border-bottom: 1px solid grey;
    background-color: white;
    padding: 10px;
    font-size: large;
}

#contact .contact-form .form-row input:focus,
#contact .contact-form .form-row textarea:focus {
    outline: none;
}

#contact .contact-form .form-row input:focus ~ label,
#contact .contact-form .form-row input:valid ~ label,
#contact .contact-form .form-row textarea:focus ~ label,
#contact .contact-form .form-row textarea:valid ~ label  {
    transform: translateY(-25px);
    font-size: 0.8em;
    font-weight: 600;
}

#contact .contact-form .form-row button {
    padding: 20px 50px;
    border:none;
    text-transform: uppercase;
    font-size: 1.1em;
    color:white;
    background-color: rgb(58, 54, 63);
    transition: all 0.3s ease;
    font-weight: 400;
    letter-spacing: 0.05em;
}

#contact .contact-form .form-row button:hover {
    background-color: rgb(68, 62, 71);
    cursor: pointer;
}

#contact .company-contact {
    background: linear-gradient(160deg, rgb(58, 47, 66) 50%, white 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 100px;
    position: relative;
    height: 500px;
}

#map {
    width: 50%;
    position: absolute;
    top: -20%;
}