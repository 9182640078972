#single-project {
    padding: 130px 50px 80px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size:cover;
    position: relative;
}

#single-project .general {
    display: flex;
}

#single-project a {
    font-size: 2.8em;
    background-color: transparent;
    color: #332554;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 170px;
    left: 7.6vw;
}

#single-project a i {
    margin-right: 2px;
}

#single-project a:hover {
    transform:scale(1.1);
    color: rgb(41, 13, 104);
}

#single-project .text {
    float: right;
    width: 35%;
    padding-left: 10px;
    border-radius: 5px;
    background: linear-gradient(300deg, #261a2b 20%, #332554 80%);
}

#single-project .text p::first-letter {
    margin-left: 0px;
    font-size: 1.1em;
}

#single-project .text p {
    margin: 5px;
    min-height: 60vh;
    border: 2px solid black;
    padding: 50px 50px 20px 50px;
    font-size: 1.2em;
    color: black;
    background-color: white;
    border-radius: 5px;
    text-align: justify;
    line-height: 1.4em;
}

#single-project .title {
    text-align: start;
    position: absolute;
    top: 13%;
    left: 20%;
    padding-top: 20px;
}

#single-project h1 {
    margin-top: 2px; 
    font-size: 3em;
    font-weight: 500;
    color:rgb(17, 23, 46);
}

#single-project h1::before {
    content: "";
    position: absolute;
    top: 43%;
    bottom: -10%;
    height  : 10px;
    width   : 50%;
    border-bottom:5px solid rgb(114, 53, 154);
}

#single-project h2 {
    text-transform: uppercase;
    font-size: 1.1em;
    color: rgb(81, 63, 114);
    margin-top: 0px;
    margin-bottom: 50px;
}

#single-project .carousel {
    width: 90%;
    margin-top: 20px;
    padding-top: 150px;
}

.alice-carousel__prev-btn {
    position: absolute;
    top: 30%;
    left: -44%;
}

.alice-carousel__prev-btn-item {
    border-radius: 25px;
    height: 20px;
    width: 50px;
    color: #332554 !important;
    font-size: 5em;
    font-weight: 900;
}

.alice-carousel__next-btn {
    position: absolute;
    top: 30%;
    right: -38%;
}

.alice-carousel__next-btn-item {
    border-radius: 25px;
    height: 20px;
    width: 50px;
    color: #332554 !important;
    font-size: 5em;
    font-weight: 900;
}

#single-project .sliderimage {
    width: 95%;
    height: 500px;
    filter:contrast(140%);
}

.alice-carousel {
    width: 65% !important;
    float: left;
}