#NotFound {
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

#NotFound .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#NotFound h1 {
    font-size: 7em;
    margin: 0;
}